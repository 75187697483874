<template>
  <div @click="getIsSubscribed"  :class="[isSubscribed ? 'body-selected': 'body-select']">
    <div :class="[isSubscribed ? 'toggle-selected': 'toggle-select']"></div>
  </div>

</template>
<script>
export default {
  name:"CustomizedSwitch",
  props:{
    isSubscribed:[Boolean],
    companyId : [Number],
    color:{
      type: [String],
      default: "#004AAD"
    }

  },
  methods: {
    getIsSubscribed() {
      // this.isSubscribed = !this.isSubscribed
      this.$emit('getCompanyId', this.companyId)
    }
  },
}
</script>
<style lang="scss" scoped >
.body-selected{
  display: flex;
  align-items: center;
  width: 30px;
  height: 18px;
  border-radius: 10px;
  border: solid 1px #004aad;
  padding: 3px;
  justify-content: flex-end;
  cursor: pointer;
}

.body-select{
  display: flex;
  align-items: center;
  width: 30px;
  height: 18px;
  border-radius: 10px;
  border: solid 1px gray;
  padding: 3px;
  cursor: pointer;
}

.toggle-selected{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: #004aad;
  transition: all 0.0001s ease-in 0s;
}

.toggle-select{
  width: 12px;
  height: 12px;
  border-radius: 50%;
  background-color: gray;
  transition: all 0.0001s ease-in 0s;
}

</style>