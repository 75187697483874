<template>
  <div class="tw-w-full tw-flex-col lg:tw-px-32 tw-px-5">
    <loa-der v-if="loading" :loading="loading" />
    <div v-else class="company">
<!--    <v-tabs background-color="transparent" v-model="tab">-->
<!--      <v-tabs-slider></v-tabs-slider>-->
<!--      <v-tab href="#all">All</v-tab>-->
<!--      <v-tab href="#subscribed">Subscribed</v-tab>-->
<!--      <v-tab href="#unsubscribed">Unsubscribed</v-tab>-->
<!--     </v-tabs>-->

      <div class="tw-flex tw-flex-wrap tw-gap-5 mt-4">
      <div v-for="(company, index) in filteredCompanies" :key="index" class="single-card mb-2">
        <v-card flat color="#f1f1f1" class="display-center-with-flex" >
          <div class="tw-hidden tw-w-full tw-flex tw-justify-end ">
            <customized-switch :color="company.companyColor" :is-subscribed="company.subscribed" :company-id="company.transportCompanyId"  @getCompanyId="getCompanyId"/>
          </div>
          <div>
          <img :src="company.companyLogo" alt="company"  class="companyLogo" />
          <span class="text-center text-capitalize" :style="{color: company.companyColor}"> {{ company.companyName }}</span>
          </div>
        </v-card>
      </div>
    </div>
    </div>
  </div>
</template>

<script>

import { mapGetters } from 'vuex';
import CustomizedSwitch from "@/components/reuseables/CustomizedSwitch";
import LoaDer from "@/components/reuseables/Loader";
import {getAllTransportCompanies, subscribeToTransportCompanies,unsubscribeFromTransportCompanies} from "@/services/api/APIService";

export default {
  name:"CompanyDashboard",
  components: {LoaDer, CustomizedSwitch},
  props: {
    selected: Number,
  },
  data() {
    return {
      subscribed: true,
      tab:"all",
      isButtonLoading: false,
      loading:false,
      filteredCompanies: [],
    };
  },
  computed:{
    ...mapGetters("companyStore", ['transportCompanies']),
    ...mapGetters('developerData', ['developerData'])
  },
  methods: {
     getCompanyId(transportCompanyId){
      this.transportCompanies.forEach(company =>{
        if(company.transportCompanyId === transportCompanyId){
          this.$store.dispatch("companyStore/toggleSubscribe",transportCompanyId).then(()=>{
            this.handleSubscription()
          })
        }
      })
    },
    async getAllTC(){
       let data = {}
      data.apiKey = this.developerData.apiKey
      this.loading = true
      let companies = []
      await getAllTransportCompanies(data).then(
          res =>{
            res.data.splice(res.data.findIndex(v => v.transportCompanyId === 38), 1);
        companies = res.data
        companies.forEach(company => company.subscribed = false)
          this.developerData.transportCompaniesSubscribeTo.forEach(subscribedCompany => {
            companies.forEach(company => {
              if (subscribedCompany.transportCompanyId === company.transportCompanyId) {
                company.subscribed = true
              }
            })
          })
          this.$store.dispatch('companyStore/setTransportCompanies', this.developerData.transportCompaniesSubscribeTo)
          this.loading = false

      })
          .catch(err =>{
          console.log(err)
          this.loading= false
          })
    },

    async handleSubscription(){
      this.isButtonLoading = true
      let companiesSubscribedTo = []
      let companiesUnsubscribedFrom = []
      this.transportCompanies.forEach(company =>{
        if (company.subscribed){
          companiesSubscribedTo.push(company)
        }
        else {
          companiesUnsubscribedFrom.push(company)
        }
      })
      let data = {}
      data.transportCompanies = companiesSubscribedTo
      data.apiKey = this.developerData.apiKey
      await subscribeToTransportCompanies(data).then(async () => {
        data.transportCompanies = companiesUnsubscribedFrom
        await unsubscribeFromTransportCompanies(data).then(() => {
          // window.location.reload()
        }).catch(err =>{
          console.log(err)
        })
      }).catch(err =>{
        console.log(err)
      })
    }
  },
  created() {
    this.getAllTC()
    this.getCompanyId()

  },
  watch: {
      tab: {
        handler: function (val) {
          if (val === "all") {
            this.filteredCompanies = this.transportCompanies;
          }
          else if(val === "subscribed"){
            this.filteredCompanies = this.transportCompanies.filter(company => company.subscribed === true);
          }
        else {
            this.filteredCompanies = this.transportCompanies.filter(company => company.subscribed === false);
          }
        },
        immediate: true,
      },
    },



};
</script>

<style lang="scss" scoped>
.v-tab {
  text-transform: none !important;
}
.text-center {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.single-card:hover {
  box-shadow: 0 0 75px -37px rgba(31, 29, 29, 1);
}
.company .v-card {
  display: flex !important;
  width: 170px;
  height: 170px;
  padding: 5px 10px;
  flex-wrap: wrap;
  flex-direction: column !important;
}
.companyLogo {
  width: 68px !important;
  height: 68px !important;
  border-radius: 50% !important;
  display: flex;
  margin: auto ;
  object-fit: contain !important;
}
.company-select-icon {
  font-size: 18px;
  right: 15px;
  position: absolute;
}
:deep(.v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev ){
  display: none !important;
}
.v-input--selection-controls {
  margin-top: 0 !important;
}

:deep(a.v-tab){
  text-transform: none !important;
  font-family: var(--fontInter);
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  color: var(--primaryColor) ;
  @media screen and (max-width: 600px) {
    line-height: 20px;
    font-size: 14px;
  }
}
.theme--light.v-tabs > .v-tabs-bar .v-tab:not(.v-tab--active) {
  font-family: var(--fontInter);
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px !important;
  color: #BDBDBD;
  @media screen and (max-width: 600px) {
    line-height: 20px;
    font-size: 14px;
  }
}

</style>
